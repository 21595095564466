import React, { useState, useEffect, useContext } from "react";
import { Loader } from "@gitlab-rtsensing/component-library";
import "./index.scss";

// Assets
import {
  apiErrorMessage,
  atleastOneArticleMsg,
  noCollectionMsg,
  noDataMessage,
} from "../../assets/errorMessageConstants";
// import FilterIcon from "../../assets/icons/filter-icon";
import InfoIcon from "../../assets/icons/info-icon";
import Trash from "../../assets/icons/trash-can";

// API
import { ProductListType } from "../../api/get-product-data-dt";
import { TopicListType } from "../../api/get-topic-data-dt";
import { PublishedListType } from "../../api/get-published-list-dt";
import { CompanyListType } from "../../api/get-company-list-dt";

// Components
// import Accordion from "../../components/accordian";
import ArticleList from "./article-list";
import Card from "../../components/card";
import CardFilter from "../../components/cards-filter";
import CreateCollectionModal from "../../components/create-collection-modal";
import DateSelector from "../../components/date-picker";
import FilterDropdown from "../../components/filter-dropdown";
import MyCollection from "../../components/my-collection";
import { Pagination } from "../../components/pagination";
import RequestPage from "../../components/request-access-page";
import UpdateCollectionModal from "../../components/update-collection-modal";
import { Input,
  Collapse,
  SearchIcon,
  Popover
} from '@opsdti-global-component-library/amgen-design-system';

// Utils
import { AppContext } from "../../utils/app-context";
import {
  apiResponse,
  filterCountFn
} from "../../utils/commonMethods";
import dayjs, { Dayjs } from "../../utils/dayjs";

const NewsCurator: React.FC = () => {
  const [articleList, setArticleList] = useState<{ [key: string]: any }>();
  const [topicList, setTopicList] = useState<TopicListType[]>([]);
  const [productList, setProductList] = useState<ProductListType[]>([]);
  const [newsDataObj, setNewsDataObj] = useState<{ [key: string]: any }>({});
  const [sortOrder, setSortOrder] = useState("desc");
  const [totalArticles, setTotalArtiles] = useState(0);
  const [uperLimt, setUperLimit] = useState(0);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [toDate, setToDate] = useState<Dayjs>(dayjs());
  const [fromDate, setFromDate] = useState<Dayjs>(dayjs().subtract(89, 'days'));

  const [isArticleLoading, setIsAticleLoadig] = useState(false);
  const [articleStatusCode, setArticlesStatusCode] = useState(200);
  const [availablePublishedList, setAvailablePublishedList] = useState<PublishedListType[]>([]);
  const [availableCompanyList, setAvailableCompanyList] = useState<CompanyListType[]>([]);
  const [kiqList, setKiqList] = useState<ProductListType[]>([]);
  const [paginationDate, setPaginationDate] = useState<any>("");
  const [avCompanyList, setAvCompanyList] = useState<ProductListType[]>([]);

  const [selectedCollection, setSelectedCollection] = useState<any>({});
  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
  const [isCollectionLoading, setCollectionIsLoading] = useState(true);
  const [isAvCompanyListLoading, setIsAvCompanyListLoading] = useState<boolean>(false)

  const [selectedArticles, setSelectedArticles] = useState<
    {
      articleName: string | undefined; // We need to set proper type for article List so we can remove undefined
      articleId: string | undefined;
    }[]
  >([]);
  const [facet, setFacet] = useState<any>({
    COMPANY: [],
    PRODUCT: [],
    TOPIC: [],
    COREX_TOPIC: [],
    KIQs: [],
    VENTUREs: [],
    PUBLISHER: []
  });
  const [publishedListError, setPublishedListError] = useState("");
  const [availableCollectionList, setAvailableCollectonList] = useState<{
    [key: string]: any;
  }>([]);

  const [isCompanFilterLoading, setIsCompanFilterLoading] = useState(true);
  const [isPublishedFilterLoading, setIsPublishedFilterLoading] = useState(true);
  const [isTopicListLoading, setIsTopicListLoading] = useState<boolean>(false);
  const [isKiqListLoading, setIsKiqListLoading] = useState<boolean>(false);
  const [isProductListLoading, setIsProductListLoading] =
    useState<boolean>(false);
  const [totalFilterCount, setTotalFilterCount] = useState<any>(0);
  const [searchedValue, setSearchedValue] = useState("");
  const [allTopicList, setAllTopicList] = useState([]);

  const currentLimit =
    uperLimt < newsDataObj.count ? uperLimt : newsDataObj.count;
  const limit: number = 50;
  const [page, setPage] = useState(1);

  type selectArticleProps = {
    articleName: string | undefined; // We need to set proper type for article List so we can remove undefined
    articleId: string | undefined;
  };

  const [isAddExistingModelOpen, setIsAddExistingModelOpen] = useState(false);
  const [isCreateNewModalOpen, setIsCreateNewModalOpen] = useState(false);
  const [selectArticlesMessage, setSelectArticlesMessage] = useState(false);
  const [isCollectionListEmpty, setIsCollectionListEmpty] = useState(false);
  const [companyListError, setCompanyListError] = useState("");
  const [articleListError, setArticleListError] = useState("");
  const [collectionListError, setCollectionListError] = useState("");
  const [kiqListError, setKiqListError] = useState("");
  const [topicsListError, setTopicsListError] = useState("");
  const [productsListError, setProductsListError] = useState("");
  const [avCompanyListError, setAvCompanyListError] = useState("");

  const [createVanish, setCreateVanish] = useState(true);
  const [addVanish, setAddVanish] = useState(true);
  const [deleteCollectionError, setDeleteCollectionError] = useState(false);

  const handlePages = (updatePage: number) => setPage(updatePage);

  const getPublisherList = async () => {
    setIsPublishedFilterLoading(true);
    try {
      const res = await apiResponse("get", "get-published-list", [], {});
      if (res?.data.data) {
        setAvailablePublishedList(res.data.data);
      } else {
        setPublishedListError(noDataMessage);
      }
      setIsPublishedFilterLoading(false);
    } catch (error) {
      setIsPublishedFilterLoading(false);
      setPublishedListError(apiErrorMessage);
    }
  };

  const getCompanysList = async () => {
    setIsCompanFilterLoading(true);
    try {
      const res = await apiResponse("get", "get-company-list", [], {});
      if (res) {
        if (res && res.data && res.data.data) {
          setAvailableCompanyList(res.data.data);
        }
      } else {
        setCompanyListError(noDataMessage);
      }
      setIsCompanFilterLoading(false);
    } catch (error) {
      setIsCompanFilterLoading(false);
      setCompanyListError(apiErrorMessage);
    }
  };

  const getAmgenVentureList = async () => {
    setIsAvCompanyListLoading(true);
    try {
      const res = await apiResponse("get", "get-amgen-company-list", [], {});
      if (res?.data.data) {
        setAvCompanyList(res.data.data);
      } else {
        setAvCompanyListError(noDataMessage);
      }
      setIsAvCompanyListLoading(false);
    } catch (error) {
      setIsAvCompanyListLoading(false);
      setAvCompanyListError(apiErrorMessage);
    }
  };

  const getKiqList = async () => {
    setIsKiqListLoading(true);
    try {
      const res = await apiResponse("post", "get-kiq-list", [], {});
      if (res?.data.data) {
        setKiqList(res.data.data);
      } else {
        setKiqListError(noDataMessage);
      }
      setIsKiqListLoading(false);
    } catch (error) {
      setIsKiqListLoading(false);
      setKiqListError(apiErrorMessage);
    }
  };

  const updateSelectedFilters = (value: {
    field: string;
    name: string;
    isChecked: boolean;
    category_name: string;
  }) => {
    if(value.field === 'TOPIC' && value.category_name === 'corex_topic'){
      value.field = 'COREX_TOPIC';
    }
    const objValue: string = value.field;

    const filterObject: any = [...facet[objValue]];
    let updatedFilterObject: any[] = [];
    if (value.isChecked) {
      filterObject.push(value.name);
      updatedFilterObject = filterObject;
    } else {
      updatedFilterObject = filterObject.filter((item: any) => {
        if (item !== value.name) {
          return true;
        }
        return false;
      });
    }
    setFacet((prevState: any) => {
      prevState[objValue] = updatedFilterObject;
      return { ...prevState };
    });
    setPage(1);
  };

  const onDateChange = (data: any) => {
    data.fieldName === "fromDate"
      ? setFromDate(data.data)
      : setToDate(data.data);
  };

  const resetFilter = () => {
    setFacet({ COMPANY: [], PRODUCT: [], TOPIC: [], KIQs: [], VENTUREs: [], PUBLISHER: [], COREX_TOPIC: []});
    setFromDate(dayjs().subtract(89, 'days'));
    setToDate(dayjs());
    setPage(1);
  };
  /********** Filter related code */

  /********** Selected Article realted code */
  const updateSelectedArticleList = (checkedArticles: selectArticleProps[]) => {
    setSelectedArticles([...checkedArticles]);
  };

  const deleteArticle = (data: any) => {
    const updatedArticles = selectedArticles.filter(
      (element) => element.articleId !== data.articleId
    );
    setSelectedArticles([...updatedArticles]);
  };
  /********** Selected Article realted code */

  /********** Collection realted code */
  const hideCollectionModal = () => {
    setIsCollectionModalOpen(false);
  };

  const deleteMyCollection = async (collectionID: string) => {
    setCollectionIsLoading(true);
    const payload = {
      collection_id: collectionID,
    };

    try {
      const res = await apiResponse("delete", "delete-collection", [], payload);
      if (res && res.data && res.data.status === "SUCCESS") {
        setIsCollectionModalOpen(false);
        setCollectionIsLoading(false);
        getCollectionList();
      }
    } catch (error) {
      setDeleteCollectionError(true);
      setTimeout(() => setDeleteCollectionError(false), 3000);
    }
  };

  const getCollectionList = async () => {
    try {
      setCollectionIsLoading(true);
      setCollectionListError("");
      setAvailableCollectonList([]);
      const payload = {
        user_id: "sensing",
        collection_name: "",
        collection_id: "",
        created_at: "",
        last_updated: "",
        env_name: "",
        page_offset: 0,
        limit: 50,
        article_ids: [],
      };

      const res = await apiResponse("post", "get-colection-list", [], payload);

      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setAvailableCollectonList(res.data.data);
      } else {
        setCollectionListError(noDataMessage);
      }
      setCollectionIsLoading(false);
    } catch (error) {
      setCollectionIsLoading(false);
      setCollectionListError(apiErrorMessage);
    }
  };

  const getCollectionDetail = (data: any) => {
    setSelectedCollection(data);
    setIsCollectionModalOpen(true);
  };

  const handleCreateCollection = (isShow: boolean) => {
    setIsCreateNewModalOpen(isShow);
  };

  const handleUpdateCollection = () => {
    setIsAddExistingModelOpen(false);
  };
  /********** Collection realted code */

  const getProductList = async () => {
    setIsProductListLoading(true);
    try {
      const res = await apiResponse("post", "product-list", [], {});
      if (res?.data.data) {
        setProductList(res.data.data);
      } else {
        setProductsListError(noDataMessage);
      }
      setIsProductListLoading(false);
    } catch (error) {
      setIsProductListLoading(false);
      setProductsListError(apiErrorMessage);
    }
  };

  const getTopicList = async () => {
    setIsTopicListLoading(true);
    try {
      const res = await apiResponse("post", "get-topic-list", [], {});
      if (res?.data.data) {
        setTopicList(res.data.data);
        setAllTopicList(res.data.data);
      } else {
        setTopicsListError(noDataMessage);
      }
      setIsTopicListLoading(false);
    } catch (error) {
      setIsTopicListLoading(false);
      setTopicsListError(apiErrorMessage);
    }
  };

    const fetchTopicList = (e: any) => {
    e.preventDefault();
    // When search field is cleared
    if(!e.target.value) {
      setTopicList(allTopicList);
      setSearchedValue("")  
    }
    if (e.target.value.length > 0) {
      const resultTopicList =  allTopicList.filter((topic:any) => {
      // toLowerCase is used as match is case-sensitive
      return topic.name.toLowerCase().match(e.target.value.toLowerCase());
      });
      setTopicList(resultTopicList)
      setSearchedValue(e.target.value)      
    };
  }

  const getArticleList = async (
    page: number,
    startDate: Dayjs,
    endDate: Dayjs
  ) => {
    setIsAticleLoadig(true);
    setArticleList([]);
    setArticleListError("");

    const usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDateLocal = startDate.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD")
    const endDateLocal = endDate.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD");

    const payload = {
      sort_by_value: sortOrder,
      start_date: startDateLocal,
      end_date: endDateLocal,
      page_offset: page - 1,
      limit: limit,

      filters: {
        company: facet.COMPANY,
        product: facet.PRODUCT,
        topic: facet.TOPIC,
        kiq: facet.KIQs,
        avCompany: facet.VENTUREs,
        publisher: facet.PUBLISHER,
        corex_topic: facet.COREX_TOPIC,
      },
    };
    try {
      const res = await apiResponse("post", "get-topic-news", [], payload);
      if (res.data.status === "SUCCESS" && res.data.data.response && res.data.data.count !== 0) {
        setArticleListError("");
        setPaginationDate(res.data.data.ingested_date);
        setArticleList(res.data.data.response);
        setNewsDataObj(res.data.data);
        setArticlesStatusCode(res.status);

        setTotalArtiles(Math.ceil(res.data.data.count / limit));
        setLowerLimit((page - 1) * limit + 1);
        setUperLimit(page * limit);
      } else {
        setArticleListError(noDataMessage);
      }
      setIsAticleLoadig(false);
    } catch (error) {
      setArticleList([]);
      setIsAticleLoadig(false);
      setArticleListError(apiErrorMessage);
    }
  };

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.newsCurator
    ) {
      getArticleList(page, fromDate, toDate);
      setTotalFilterCount(filterCountFn(facet));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortOrder,
    page,
    fromDate,
    toDate,
    facet.COMPANY.length,
    facet.PRODUCT.length,
    facet.TOPIC.length,
    facet.COREX_TOPIC.length,
    facet.KIQs.length,
    facet.VENTUREs.length,
    facet.PUBLISHER.length
  ]);

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.newsCurator
    ) {
      setIsAticleLoadig(true);
      getPublisherList();
      getCompanysList();
      getCollectionList();
      authData?.externalAuthorization?.topics && getTopicList();
      authData?.externalAuthorization?.products && getProductList();
      authData?.externalAuthorization?.kiq && getKiqList();
      authData?.externalAuthorization?.avCompany && getAmgenVentureList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateNewCollection = () => {
    setIsCreateNewModalOpen(false);
    selectedArticles && selectedArticles.length !== 0
      ? setIsCreateNewModalOpen(true)
      : setSelectArticlesMessage(true);
    setTimeout(() => setSelectArticlesMessage(false), 3000);
  };

  const checkCollectionList = () => {
    setIsCollectionListEmpty(true);
    setTimeout(() => setIsCollectionListEmpty(false), 3000);
  };

  const handleAddToExisting = () => {
    setIsAddExistingModelOpen(false);
    selectedArticles && selectedArticles.length !== 0
      ? availableCollectionList && availableCollectionList.length !== 0
        ? setIsAddExistingModelOpen(true)
        : checkCollectionList()
      : setSelectArticlesMessage(true);
    setTimeout(() => setSelectArticlesMessage(false), 3000);
  };

  const vanishCreateCollection = () => {
    setCreateVanish(false);
  };
  const vanishUpdateCollection = () => {
    setAddVanish(false);
  };

  const completeRemove = () => {
    setIsCollectionModalOpen(false);
  };

  const formatFilterCount = (filterCount: number) => {
    if(filterCount === 0) return ""
    return `(${filterCount})`
  }

  const { authData } = useContext(AppContext);

  let items = [
    {
      children: <FilterDropdown
        label="PUBLISHER"
          options={availablePublishedList}
          isLoading={isPublishedFilterLoading}
          checkedOptions={facet.PUBLISHER}
          onClickCheckbox={updateSelectedFilters}
          error={publishedListError}
        />,
        key: '1',
        label: `Publisher ${formatFilterCount(facet['PUBLISHER'].length)}`
    },
    {
      children: <FilterDropdown
        label="COMPANY"
        isLoading={isCompanFilterLoading}
        options={availableCompanyList}
        checkedOptions={facet.COMPANY}
        onClickCheckbox={updateSelectedFilters}
        error={companyListError}
      />,
      key: '2',
      label: `Company ${formatFilterCount(facet['COMPANY'].length)}`
    }
  ]

    if(authData?.externalAuthorization?.products){
      items.push({
        children: <FilterDropdown
          label="PRODUCT"
          options={productList}
          isLoading={isProductListLoading}
          checkedOptions={facet.PRODUCT}
          onClickCheckbox={updateSelectedFilters}
          error={productsListError}
        />,
        key: '3',
        label: `Product ${formatFilterCount(facet['PRODUCT'].length)}`
      })
    }
    if(authData?.externalAuthorization?.kiq){
      items.push({
        children: <FilterDropdown
          label="KIQs"
          options={kiqList}
          isLoading={isKiqListLoading}
          checkedOptions={facet.KIQs}
          onClickCheckbox={updateSelectedFilters}
          error={kiqListError}
        />,
        key: '4',
        label: `KIQs ${formatFilterCount(facet['KIQs'].length)}`
      })
    }
    if(authData?.externalAuthorization?.topics){
      items.push({
        children: <>
          <Input
            className="topic-search"
            placeholder="Search Topics"
            value={searchedValue}
            onChange={(e: any) => fetchTopicList(e)}
            suffixContent={<SearchIcon width={16} height={16} className='topic-search-icon' fill="rgba(0, 0, 0, 0.45)"/>}
          />
          <FilterDropdown
            label="TOPIC"
            options={topicList}
            isLoading={isTopicListLoading}
            checkedOptions={[...facet.TOPIC, ...facet.COREX_TOPIC]}
            onClickCheckbox={updateSelectedFilters}
            error={topicsListError}
          />
        </>,
        key: '5',
        label: `Topic ${formatFilterCount([...facet.TOPIC, ...facet.COREX_TOPIC].length)}`
      })
    }
    if(authData?.externalAuthorization?.avCompany){
      items.push({
        children: <FilterDropdown
          label="VENTUREs"
          options={avCompanyList}
          isLoading={isAvCompanyListLoading}
          checkedOptions={facet.VENTUREs}
          onClickCheckbox={updateSelectedFilters}
          error={avCompanyListError}
        />,
        key: '6',
        label: `VENTUREs ${formatFilterCount(facet['VENTUREs'].length)}`
      })
    }

  return authData && authData?.externalAuthorization?.newsCurator ? (
    <div className="ext-news-curator-container ops-px-4">
      <div className="ext-row">
        <div className="ext-news-curator-left-panel ext-col-lg-2 ops-my-2 ext-col-md-12">
          <div className="ext-news-curator-filter-section">
            <CardFilter
              cardHeader="Filter Articles"
              totalFilterCount={totalFilterCount}
            >
              <>
                <div className="filter-card-container">
                  <div className="ext-filter-element-container">
                    <DateSelector
                      selectedDate={fromDate}
                      label="PUBLISHED BETWEEN"
                      verbageText="Select publish date"
                      minDate={dayjs("2022-01-03")}
                      maxDate={toDate}
                      onChange={(date: Dayjs) =>
                        onDateChange({
                          data: date,
                          fieldName: "fromDate",
                        })
                      }
                    />
                    <DateSelector
                      selectedDate={toDate}
                      minDate={fromDate}
                      maxDate={dayjs()}
                      label="AND"
                      onChange={(date) =>
                        onDateChange({ data: date, fieldName: "toDate" })
                      }
                    />
                    <div style={{marginTop: 24, width: '100%'}} />
                    <Collapse
                      defaultActiveKey={1}
                      items={items}
                    />
                  </div>
                  <div className="ext-reset-filter-section ext-border-top">
                    <div
                      className="ext-fs-reset-filter-styling ops-mx-4 ops-mb-2 ext-cursor-pointer"
                      onClick={() => resetFilter()}
                    >
                      RESET FILTERS
                    </div>
                  </div>
                </div>
              </>
            </CardFilter>
          </div>
        </div>

        <div className="ext-news-curator-right-panel ext-col-lg-10 ext-col-md-12 ops-my-2">
          <div className="ext-news-curator-article-list">
            <Card cardHeader="Articles">
              <div className="ext-article-sorting-wrapper ext-row ops-px-4">
                <div className="ext-fst-italic ext-article-card-note ext-col-md-9">
                  Select articles to be added to a new or existing collection
                </div>
                <div className="ext-col ops-my-0">
                  <select
                    className="ext-article-card-select ext-w-100 ops-mt-2"
                    onChange={(event) =>
                      setSortOrder(event.currentTarget.value)
                    }
                  >
                    <option value="desc">&nbsp;&nbsp; Show Newest First</option>
                    <option value="asc">&nbsp;&nbsp; Show Oldest First</option>
                  </select>
                </div>
              </div>
              <div className="ext-article-list-wrapper ext-border-top-0">
                {!isArticleLoading &&
                  articleList?.count === 0 &&
                  articleStatusCode === 200 && (
                    <div className="ops-px-2 ops-mt-2">
                      No articles found based on selected filter values. Please
                      change the filter values and try again.
                    </div>
                  )}
                <ArticleList
                  isArticleLoading={isArticleLoading}
                  articleList={articleList}
                  onSelectedArticlesChanged={updateSelectedArticleList}
                  selectedArticles={selectedArticles}
                  error={articleListError}
                  tagFilters={facet}
                  updateSelectedFilters={updateSelectedFilters}
                />
              </div>

              <div className="ext-article-pagination">
                <div className="container">
                  <div className="ext-article-pagination-wrapper ext-row ops-px-2">
                    {!isArticleLoading && articleList?.length !== 0 && (
                      <>
                        <div className="ext-text-pagination ext-col">
                          Showing {lowerLimit} - {currentLimit} of{" "}
                          {newsDataObj.count} results, based on last database
                          update at {paginationDate.replace("PT", "PT on")} |
                          View Sources
                          <Popover
                            id="newsCuratorToolTip"
                            data-html={true}
                            content={availablePublishedList.map(
                              (e: any, index: number) => {
                                return (
                                  <span key={index}>
                                    {e.display_name}
                                    <br />
                                  </span>
                                );
                              }
                            )}
                          >
                            <a data-tooltip-id="newsCuratorToolTip" href="#/">
                              <span className="cursor-pointer ops-ms-2">
                                <InfoIcon fill="#2F2F2F" />
                              </span>
                            </a>
                          </Popover>
                        </div>
                        <div className="ext-col-lg-6 ext-col-md-12 ext-col-sm-12 ops-mt-sm-2 ops-mt-md-2 ops-mt-lg-0">
                          <Pagination
                            page={page}
                            totalPages={totalArticles}
                            handlePagination={handlePages}
                          />
                        </div>
                      </>
                    )}
                    {!isArticleLoading && articleList?.count === 0 && (
                      <div className="ops-px-2">Articles not found</div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="ext-row flex-sm-wrap-reverse flex-md-wrap-reverse">
        <div className="ext-news-curator-left-panel ext-col-lg-2 ext-col-md-12 ops-mt-lg-2">
          <div className="ext-news-curator-collection-section ops-mb-2">
            <Card cardHeader="My Article Collections">
              <>
                {isCollectionLoading && (
                  <div className="ext-d-flex ext-news-curator-loader ">
                    <Loader />
                  </div>
                )}
                <div className="ext-mc-card-body">
                  {collectionListError && (
                    <div className="ext-fw-bold text-secondary ops-px-6 ops-pt-4">
                      {collectionListError}
                    </div>
                  )}
                  {!collectionListError && !isCollectionLoading && (
                    <div className="ext-collection-list">
                      {availableCollectionList.length !== 0 &&
                        availableCollectionList.map(
                          (element: any, index: number) => {
                            return (
                              <div
                                className="ops-mx-4 ops-my-2 ext-collections-ul"
                                key={index}
                                onClick={() => getCollectionDetail(element)}
                              >
                                {element.collection_name}
                              </div>
                            );
                          }
                        )}
                    </div>
                  )}
                </div>
                <div className="ext-align-items-center">
                  {isCollectionModalOpen &&
                    Object.keys(selectedCollection).length > 0 && (
                      <MyCollection
                        show={isCollectionModalOpen}
                        onHideModal={() => hideCollectionModal()}
                        collectionId={selectedCollection.collection_id}
                        collectionName={selectedCollection.collection_name}
                        onDeleteCollection={(id) => deleteMyCollection(id)}
                        completeRemove={() => {
                          completeRemove();
                        }}
                        deleteCollectionError={deleteCollectionError}
                      />
                    )}
                </div>
              </>
            </Card>
          </div>
        </div>

        <div className="ext-news-curator-right-panel ext-col-lg-10 ext-col-md-12 ops-mb-4 ops-mt-lg-2">
          <div className="ext-news-curator-selected-articles-section">
            <Card cardHeader="Selected Articles">
              <div className="ext-fst-italic ops-m-2 ext-selected-articles-header">
                You can add selected stories to an existing collection or create
                a new one.
              </div>
              <div className="ext-selected-articles-container">
                <div>
                  {selectedArticles.map((item, index) => (
                    <div
                      key={index}
                      className={
                        index % 2 === 0
                          ? "ext-selected-article  ext-card-gray"
                          : "ext-selected-article"
                      }
                    >
                      <div className="ext-article-list-data">
                        {item.articleName}
                      </div>
                      <div
                        className="ext-cursor-pointer"
                        onClick={() => deleteArticle(item)}
                      >
                        <Trash fill="#797979" classname="ext-tag-icon" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="ext-selected-articles-buttons">
                {selectArticlesMessage && (
                  <span className="ext-article-error-message ext-text-danger ext-align-middle ext-fst-italic ops-me-2">
                    {atleastOneArticleMsg}
                  </span>
                )}
                {isCollectionListEmpty && (
                  <span className="ext-article-error-message ext-align-middle ext-text-danger ext-fst-italic ops-me-2">
                    {noCollectionMsg}
                  </span>
                )}

                <div className="ext-row ext-footer-btn">
                  <div
                    className="ext-col-sm-12 ext-col-lg-6 ext-add-new-btn ext-news-curator-btn ext-cursor-pointer"
                    onClick={() => {
                      handleAddToExisting();
                      setAddVanish(true);
                    }}
                    style={{ marginTop: "8px" }}
                  >
                    Add to Existing Collection
                  </div>
                  <div
                    className="ext-col-sm-12 ext-col-lg-6 ext-create-btn ext-news-curator-btn ext-cursor-pointer"
                    onClick={() => {
                      handleCreateNewCollection();
                      setCreateVanish(true);
                    }}
                    style={{ marginTop: "8px" }}
                  >
                    Create New Collection
                  </div>
                </div>
              </div>
            </Card>
          </div>

          {createVanish && (
            <CreateCollectionModal
              show={isCreateNewModalOpen}
              hideModal={handleCreateCollection}
              getCollectionList={() => getCollectionList()}
              selectedArticle={selectedArticles}
              updateSelectedArticleList={updateSelectedArticleList}
              vanish={() => vanishCreateCollection()}
            />
          )}

          {addVanish && (
            <UpdateCollectionModal
              show={isAddExistingModelOpen}
              hideModal={() => handleUpdateCollection()}
              getCollectionList={() => getCollectionList()}
              selectedArticle={selectedArticles}
              availableCollectionList={availableCollectionList}
              updateSelectedArticleList={updateSelectedArticleList}
              vanish={() => vanishUpdateCollection()}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default NewsCurator;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./index.scss";

import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "@gitlab-rtsensing/component-library";
import RequestPage from "../../components/request-access-page";

import ArticleCard from "../../components/article-card";
import BarGraph from "../../components/graph/bar-graph";
import Card from "../../components/card";
import TrendingWorldCloud from "../../components/trending-word-cloud";

import { amgenTicker, getApiUrl } from "../../api/api-config";
import { getFormatedTagList, getOktaToken } from "../../utils/commonMethods";
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import { AppContext } from "../../utils/app-context";
import { BASE_ROUTE } from '../../utils/routing'
import "../../assets/css/bootstrap.scss";

const TrendingPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const oktaToken = getOktaToken();
  const [articlesLoading, setArticlesLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [articleList, setArticleList] = useState([]);
  const [getCompanyList, setGetCompanyList] = useState([]);
  const [getProductList, setProductList] = useState([]);
  const [newsErrorMessage, setNewsErrorMessage] = useState<string>("");
  const [companyDurationKey, setCompanyDurationKey] = useState("7days");
  const [productDurationKey, setProductDurationKey] = useState("30days");
  const [companyErrorMessage, setCompanyErrorMessage] = useState<string>("");
  const [productErrorMessage, setProductErrorMessage] = useState<string>("");
  const [companyDuration, setCompanyDuration] = useState([
    { label: "Last 7 days", name: "7days", value: true },
    { label: "Last 30 days", name: "30days", value: false },
    { label: "Today", name: "TODAY", value: false },
  ]);
  const [productDuration, setProductDuration] = useState([
    { label: "Last 30 days", name: "30days", value: true },
    { label: "Last 60 days", name: "60days", value: false },
    { label: "Last 90 days", name: "90days", value: false },
  ]);

  const companyDurationTab = (selectedView: any) => {
    const updatedDurationViews = companyDuration.map((view: any) => {
      if (view.label === selectedView.label) {
        view.value = true;
      } else {
        view.value = false;
      }
      return view;
    });
    setCompanyDuration([...updatedDurationViews]);
    setCompanyDurationKey(selectedView.name);
  };

  const productDurationTab = (selectedView: any) => {
    const updatedDurationViews = productDuration.map((view: any) => {
      if (view.label === selectedView.label) {
        view.value = true;
      } else {
        view.value = false;
      }
      return view;
    });
    setProductDuration([...updatedDurationViews]);
    setProductDurationKey(selectedView.name);
  };

  const redirectToCompanyPage = () => {
    navigate(`${BASE_ROUTE}/companies/${amgenTicker}`, {
      state: { companyId: amgenTicker, callingPath: location },
    });
  };

  const getArticleList = async () => {
    try {
      setArticlesLoading(true);
      const payload = {
        company_list: [amgenTicker],
        sort_by_value: "desc",
        page_offset: 0,
        limit: 10,
        filters: { company: [`${amgenTicker}`] },
      };

      const res = await axios({
        method: "post",
        url: getApiUrl("get-news-with-tags"),
        data: payload,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });

      if (res.data.status === "SUCCESS" && res.data.data.response && res.data.data.count !== 0) {
        setArticleList(res.data.data.response);
      } else {
        setArticleList([]);
        setNewsErrorMessage(noDataMessage);
      }
      setArticlesLoading(false);
    } catch (error) {
      setArticleList([]);
      setArticlesLoading(false);
      setNewsErrorMessage(apiErrorMessage);
    }
  };

  const getCompanyLists = async () => {
    try {
      setCompanyLoading(true);
      const res = await axios({
        method: "post",
        url: `${getApiUrl(
          "get-company-list-graph"
        )}?range=${companyDurationKey}`,

        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data && res.data.status === "SUCCESS") {
        setGetCompanyList(res.data.data);
      } else {
        setGetCompanyList([]);
        setCompanyErrorMessage(noDataMessage);
      }
      setCompanyLoading(false);
    } catch (error) {
      setGetCompanyList([]);
      setCompanyLoading(false);
      setCompanyErrorMessage(apiErrorMessage);
    }
  };

  const getProductLists = async () => {
    try {
      setProductLoading(true);

      const res = await axios({
        method: "post",
        url: `${getApiUrl(
          "get-product-list-graph"
        )}?range=${productDurationKey}`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data && res.data.status === "SUCCESS") {
        setProductLoading(false);
        setProductList(res.data.data);
      } else {
        setProductList([]);
        setProductErrorMessage(noDataMessage);
      }
      setProductLoading(false);
    } catch (error) {
      setProductList([]);
      setProductLoading(false);
      setProductErrorMessage(apiErrorMessage);
    }
  };

  const { authData } = useContext(AppContext);
  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.trending
    ) {
      getCompanyLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDurationKey]);

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.trending
    ) {
      getArticleList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.trending
    ) {
      getProductLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDurationKey]);

  return authData && authData?.externalAuthorization?.trending ? (
    <div className="ext-trending-page ext-row ops-mx-0 ops-px-2">
      <div className="ext-containers ext-col-12 ext-col-md-6">
        <Card cardHeader="Companies in the News">
          <>
            <div className="navbar-section chart-navbar-width ext-d-flex ops-ms-2">
              <ul className="nav ext-section-name ext-inline-element-item ext-cursor-pointer ops-pt-4">
                {companyDuration.map((data: any) => {
                  return (
                    <li
                      key={data.label}
                      className={
                        data.value
                          ? "nav-item active ops-px-4 ops-pb-2"
                          : "nav-item inactive ops-px-4 ops-pb-2"
                      }
                      onClick={() => companyDurationTab(data)}
                    >
                      {data.label}
                    </li>
                  );
                })}
              </ul>
            </div>
            {!companyLoading && companyErrorMessage && (
              <div className="ext-empty-chart-msg">{noDataMessage}</div>
            )}
            {companyLoading ? (
              <div className="ext-d-flex ext-trending-loader">
                <div>
                  <Loader />
                </div>
              </div>
            ) : (
              !companyErrorMessage && (
                <BarGraph
                  chartType="company"
                  data={getCompanyList}
                  top={30}
                  bottom={0}
                  left={50}
                  right={50}
                  duration={companyDurationKey}
                />
              )
            )}
          </>
        </Card>
      </div>
      <div className="ext-containers ext-col-12 ext-col-md-6">
        <Card cardHeader="Products in the News">
          <>
            <div className="navbar-section chart-navbar-width  ops-ms-2">
              <ul className="nav ext-section-name ext-inline-element-item  ext-cursor-pointer ops-pt-4">
                {productDuration.map((data: any) => {
                  return (
                    <li
                      key={data.label}
                      className={
                        data.value
                          ? "nav-item active ops-px-4 ops-pb-2"
                          : "nav-item inactive ops-px-4 ops-pb-2"
                      }
                      onClick={() => productDurationTab(data)}
                    >
                      {data.label}
                    </li>
                  );
                })}
              </ul>
            </div>
            {!productLoading && productErrorMessage && (
              <div className="ext-empty-chart-msg">{noDataMessage}</div>
            )}
            {productLoading ? (
              <div className="ext-d-flex ext-trending-loader">
                <div>
                  <Loader />
                </div>
              </div>
            ) : (
              !productErrorMessage && (
                <BarGraph
                  chartType="product"
                  data={getProductList}
                  top={30}
                  bottom={0}
                  left={30}
                  right={50}
                  duration={productDurationKey}
                />
              )
            )}
          </>
        </Card>
      </div>
      <div className="ext-containers ext-col-12 ext-col-md-6">
        <Card cardHeader="Topics in the News">
          <TrendingWorldCloud /> 
        </Card>
      </div>
      <div className="ext-containers ext-col-12 ext-col-md-6">
        <Card cardHeader="Amgen in the News">
          <div className="ext-article-list-wrapper ext-card-height ext-border-top-0">
            {articlesLoading && <Loader />}
            {!articlesLoading && newsErrorMessage && (
              <div className="ext-empty-chart-msg">{noDataMessage}</div>
            )}
            {articleList &&
              articleList.map((article: any, index: number) => {
                let specialClassName =
                  index % 2 === 0
                    ? "ext-article-background-gray"
                    : "ext-article-background-white";

                const tags = getFormatedTagList(
                  navigate,
                  location,
                  article.company_names,
                  article.product_names,
                  article.topic_names,
                  article.kiq_names,
                  article.av_company_names,
                  article.corex_topic_names
                );

                return (
                  <ArticleCard
                    key={index}
                    redirectUrl={article.url}
                    specialClassName={specialClassName}
                    title={article.title}
                    articleId={article.article_id}
                    publishDate={article.published}
                    publishSource={article.source_name}
                    tagList={tags}
                    checkboxFlag={false}
                    trashFlag={false}
                  />
                );
              })}
          </div>

          <div className="ext-article-pagination">
            <div className="container">
              <div className="ext-row ops-px-2 ops-py-4 ext-article-pagination-wrapper">
                <div
                  className="trending-footer-button"
                  onClick={() => redirectToCompanyPage()}
                >
                  <div className="trending-botton-text ops-pt-1">
                    VIEW ALL AMGEN NEWS
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default TrendingPage;

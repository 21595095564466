import { useState, useEffect, useContext } from "react";
import ReactWordcloud from "react-wordcloud";
import axios from "axios";
import { getApiUrl } from "../../api/api-config";
import { getOktaToken } from "../../utils/commonMethods";
import { WordCloudDataType } from "../../api/get-word-cloud-dt";
import {
  noDataMessage,
  apiErrorMessage,
} from "../../assets/errorMessageConstants";
import "./index.scss";
import { Loader } from "@gitlab-rtsensing/component-library";
import { AppContext } from "../../utils/app-context";

const WorldCloud: React.FC = () => {
  const [wordCloudData, setWordCloudData] = useState<WordCloudDataType[]>([]);
  const [wordCloudErrorMessage, setWordCloudErrorMessage] = useState("");
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [worldCloudDuration, setWorldCloudDuration] = useState([
    { name: "30 days", value: true },
    { name: "90 days", value: false },
    { name: "Last 12 Months", value: false },
  ]);

  const [highlightedWord, setHighLightedWord] = useState(null);
  const callbacks = {
    getWordColor: (e: any) =>
      e.text === highlightedWord ? "#3399ff" : "#0063C3",
    getWordTooltip: () => " ",
    onWordMouseOver: (target: any) => {
      setHighLightedWord(target.text);
    },
    onWordMouseOut: () => {
      setHighLightedWord(null);
    },
  };

  const oktaToken = getOktaToken();
  const fetchWordCloudData = async (view: any) => {
    let range = "";
    if (view === "30 days") {
      range = "month";
    } else if (view === "90 days") {
      range = "3mos";
    } else {
      range = "year";
    }
    setIsDataLoading(true);
    setWordCloudErrorMessage("");
    try {
      const res = await axios({
        method: "post",
        url: `${getApiUrl("world-cloud")}?range=${range}&word_count=25`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS" && res.data.data.length > 0) {
        let newArray: any = [];

        // eslint-disable-next-line array-callback-return
        res.data.data.map((ele: any, index: number) => {
          newArray.push({ text: ele.text, value: ele.value * 1000 });
        });
        setWordCloudData(newArray);
      } else {
        setWordCloudErrorMessage(noDataMessage);
      }
      setIsDataLoading(false);
    } catch (error) {
      setWordCloudData([]);
      setIsDataLoading(false);
      setWordCloudErrorMessage(apiErrorMessage);
    }
  };
  const { authData } = useContext(AppContext);
  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.trending
    ) {
      fetchWordCloudData("30 days");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDurationTabs = (selectedView: any) => {
    const updatedDurationViews = worldCloudDuration.map((view: any) => {
      if (view.name === selectedView.name) {
        view.value = true;
      } else {
        view.value = false;
      }
      return view;
    });
    setWorldCloudDuration([...updatedDurationViews]);
    fetchWordCloudData(selectedView.name);
  };

  return (
    <div className="ext-word-cloud ">
      <div className="navbar-section ext-navbar-width ext-d-flex ops-ms-2">
        <ul className="nav ext-section-name ext-cursor-pointer ext-align-item-inline ops-pt-4">
          {worldCloudDuration.map((view) => {
            return (
              <li
                key={view.name}
                className={
                  view.value
                    ? "nav-item active ops-px-4 ops-pb-2"
                    : "nav-item inactive ops-px-4 ops-pb-2"
                }
                onClick={() => handleDurationTabs(view)}
              >
                {view.name}
              </li>
            );
          })}
        </ul>
      </div>
      {isDataLoading && (
        <div className="ext-d-flex ext-justify-content-center ext-h-100 ops-pb-12">
          <Loader />
        </div>
      )}
      {wordCloudErrorMessage && (
        <div className="ext-empty-chart-msg">{wordCloudErrorMessage}</div>
      )}
      {!isDataLoading && !wordCloudErrorMessage && (
        <div className="ops-pt-2 ext-wordcloud-wrapper">
          <ReactWordcloud
            callbacks={callbacks}
            options={{
              rotations: 2,
              rotationAngles: [0, 0],
              fontSizes: [13, 67],
              fontFamily: "Inter",
              deterministic: true,
            }}
            words={wordCloudData}
          />
        </div>
      )}
    </div>
  );
};

export default WorldCloud;

import React, { useState, useEffect, useContext } from "react";
import { Loader } from "@gitlab-rtsensing/component-library";
import { useNavigate, useLocation } from "react-router-dom";
import "../oncology/index.scss";

// API
import { ProductListType } from "../../api/get-product-data-dt";
import { TopicListType } from "../../api/get-topic-data-dt";
import { PublishedListType } from "../../api/get-published-list-dt";
import { CompanyListType } from "../../api/get-company-list-dt";

// Assets
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
// import FilterIcon from "../../assets/icons/filter-icon";

// Components
// import Accordion from "../../components/accordian";
import ArticleCard from "../../components/article-card-dscu";
import Card from "../../components/card";
import CardFilter from "../../components/cards-filter";
import DateSelector from "../../components/date-picker";
import ErrorMessage from "../../components/error-message";
import FilterDropdown from "../../components/filter-dropdown";
// import { Pagination } from "../../components/pagination";
import RequestPage from "../../components/request-access-page";
import { 
  Button,
  Title,
  Pagination,
  // Breadcrumb,
  Collapse,
  Switch,
  Select,
  Paragraph,
  SearchIcon,
  Input
} from '@opsdti-global-component-library/amgen-design-system'

// Utils
import { SEARCH_TEXT, SWITCH_SUMMARIES_TEXT, SELECT_OPTIONS} from "../news-consts";
import { AppContext } from "../../utils/app-context";
import {
  apiResponse,
  filterCountFn,
  getFormatedTagListWithFilter,
} from "../../utils/commonMethods";
import dayjs, { Dayjs } from "../../utils/dayjs";


const ellipsisRequired = true;

const News: React.FC = () => {
  const [articleList, setArticleList] = useState<{ [key: string]: any }>();
  const [sortOrder, setSortOrder] = useState("desc");
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [upperLimit, setUpperLimit] = useState(0);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [availablePublishedList, setAvailablePublishedList] = useState<PublishedListType[]>([]);
  const [availableCompanyList, setAvailableCompanyList] = useState<CompanyListType[]>([]);
  const [topicList, setTopicList] = useState<TopicListType[]>([]);
  const [productList, setProductList] = useState<ProductListType[]>([]);
  const [kiqList, setKiqList] = useState<ProductListType[]>([]);
  const [avCompanyList, setAvCompanyList] = useState<ProductListType[]>([]);
  const [newsDataObj, setNewsDataObj] = useState<{ [key: string]: any }>({});
  const [paginationDate, setPaginationDate] = useState<string>("");
  const [totalFilterCount, setTotalFilterCount] = useState<number>(0);

  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(89, 'days'));

  const [isPublishedFilterLoading, setIsPublishedFilterLoading] =
    useState(true);
  const [isCompanFilterLoading, setIsCompanFilterLoading] = useState(true);
  const [isTopicListLoading, setIsTopicListLoading] = useState<boolean>(false);
  const [isProductListLoading, setIsProductListLoading] =
    useState<boolean>(false);
  const [isKiqListLoading, setIsKiqListLoading] = useState<boolean>(false);
  const [isAvCompanyListLoading, setIsAvCompanyListLoading] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [allTopicList, setAllTopicList] = useState([]);

  const [facet, setFacet] = useState<any>({
    COMPANY: [],
    PUBLISHER: [],
    PRODUCT: [],
    TOPIC: [],
    COREX_TOPIC: [],
    LOCATION: [],
    KIQs: [],
    VENTUREs: []
  });
  const [publishedListError, setPublishedListError] = useState("");
  const [companyListError, setCompanyListError] = useState("");
  const [articleListError, setArticleListError] = useState("");
  const [topicsListError, setTopicsListError] = useState("");
  const [productsListError, setProductsListError] = useState("");
  const [kiqListError, setKiqListError] = useState("");
  const [avCompanyListError, setAvCompanyListError] = useState("");
  const [showArticleSummary, setShowArticleSummary] = useState(true);

  const currentLimit =
    upperLimit < newsDataObj.count ? upperLimit : newsDataObj.count;
  const limit: number = 50;
  const todayDate: any = dayjs();

  const handlePages = (updatePage: number) => setPage(updatePage);

  const getSelectedValue = (value: {
    field: string;
    name: string;
    isChecked: boolean;
    category_name: string;
  }) => {
    if(value.field === 'TOPIC' && value.category_name === 'corex_topic'){
      value.field = 'COREX_TOPIC';
    }
    const objValue: string = value.field;

    const filterObject: any = [...facet[objValue]];
    let updatedFilterObject: any[] = [];
    if (value.isChecked) {
      filterObject.push(value.name);
      updatedFilterObject = filterObject;
    } else {
      updatedFilterObject = filterObject.filter((item: any) => {
        if (item !== value.name) {
          return true;
        }
        return false;
      });
    }

    setFacet((prevState: any) => {
      prevState[objValue] = updatedFilterObject;
      return { ...prevState };
    });
    setPage(1);
  };

  const getPublisherList = async () => {
    setIsPublishedFilterLoading(true);
    try {
      const res = await apiResponse("get", "get-published-list", [], {});
      if (res?.data.data) {
        setAvailablePublishedList(res.data.data);
      } else {
        setPublishedListError(noDataMessage);
      }
      setIsPublishedFilterLoading(false);
    } catch (error) {
      setIsPublishedFilterLoading(false);
      setPublishedListError(apiErrorMessage);
    }
  };

  const getProductList = async () => {
    setIsProductListLoading(true);
    try {
      const res = await apiResponse("post", "product-list", [], {});
      if (res?.data.data) {
        setProductList(res.data.data);
      } else {
        setProductsListError(noDataMessage);
      }
      setIsProductListLoading(false);
    } catch (error) {
      setIsProductListLoading(false);
      setProductsListError(apiErrorMessage);
    }
  };

  const getKiqList = async () => {
    setIsKiqListLoading(true);
    try {
      const res = await apiResponse("post", "get-kiq-list", [], {});
      if (res?.data.data) {
        setKiqList(res.data.data);
      } else {
        setKiqListError(noDataMessage);
      }
      setIsKiqListLoading(false);
    } catch (error) {
      setIsKiqListLoading(false);
      setKiqListError(apiErrorMessage);
    }
  };

  const getAmgenVentureList = async () => {
    setIsAvCompanyListLoading(true);
    try {
      const res = await apiResponse("get", "get-amgen-company-list", [], {});
      if (res?.data.data) {
        setAvCompanyList(res.data.data);
      } else {
        setAvCompanyListError(noDataMessage);
      }
      setIsAvCompanyListLoading(false);
    } catch (error) {
      setIsAvCompanyListLoading(false);
      setAvCompanyListError(apiErrorMessage);
    }
  };

  const getTopicList = async () => {
    setIsTopicListLoading(true);
    setTopicList([]);
    try {
      const res = await apiResponse("post", "get-topic-list", [], {});
      if (res?.data.data) {
        setTopicList(res.data.data);
        setAllTopicList(res.data.data);
      } else {
        setTopicsListError(noDataMessage);
      }
      setIsTopicListLoading(false);
    } catch (error) {
      setIsTopicListLoading(false);
      setTopicsListError(apiErrorMessage);
    }
  };

  const getCompanysList = async () => {
    setIsCompanFilterLoading(true);
    try {
      const res = await apiResponse("get", "get-company-list", [], {});
      if (res?.data.data) {
        setAvailableCompanyList(res.data.data);
      } else {
        setCompanyListError(noDataMessage);
      }
      setIsCompanFilterLoading(false);
    } catch (error) {
      setIsCompanFilterLoading(false);
      setCompanyListError(apiErrorMessage);
    }
  };

  const fetchTopicList = (e: any) => {
    e.preventDefault();
    // When search field is cleared
    if(!e.target.value) {
      setTopicList(allTopicList);
      setSearchedValue("")  
    }
    if (e.target.value.length > 0) {
      const resultTopicList =  allTopicList.filter((topic:any) => {
      // toLowerCase is used as match is case-sensitive
      return topic.name.toLowerCase().match(e.target.value.toLowerCase());
      });
      setTopicList(resultTopicList)
      setSearchedValue(e.target.value)      
    };
  }

  const getArticleList = async (
    page: number,
    startDate: Dayjs,
    endDate: Dayjs
  ) => {
    setLoading(true);
    setArticleList([]);
    setArticleListError("");

    const usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDateLocal = startDate.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD")
    const endDateLocal = endDate.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD");

    const payload = {
      sort_by_value: sortOrder,
      start_date: startDateLocal,
      end_date: endDateLocal,
      page_offset: page - 1,
      limit: limit,

      filters: {
        publisher: facet.PUBLISHER,
        company: facet.COMPANY,
        product: facet.PRODUCT,
        topic: facet.TOPIC,
        corex_topic: facet.COREX_TOPIC,
        kiq: facet.KIQs,
        avCompany: facet.VENTUREs
      },
    };
    try {
      const res = await apiResponse("post", "get-topic-news", [], payload);
      if (res.data.status === "SUCCESS" && res.data.data.response && res.data.data.count !== 0) {
        setPaginationDate(res.data.data.ingested_date);
        setArticleList(res.data.data.response);
        setNewsDataObj(res.data.data);
        setLowerLimit((page - 1) * limit + 1);
        setUpperLimit(page * limit);
      } else {
        setArticleListError(noDataMessage);
      }
      setLoading(false);
    } catch (error) {
      setArticleList([]);
      setLoading(false);
      setArticleListError(apiErrorMessage);
    }
  };



  const onDateChange = (data: any) => {
    data.fieldName === "fromDate"
      ? setStartDate(data?.data)
      : setEndDate(data?.data);
  };

  const navigate = useNavigate();
  const location: any = useLocation();

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.news
    ) {
      getArticleList(page, startDate, endDate);
      setTotalFilterCount(filterCountFn(facet));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortOrder,
    page,
    facet.COMPANY.length,
    facet.PRODUCT.length,
    facet.TOPIC.length,
    facet.COREX_TOPIC.length,
    facet.PUBLISHER.length,
    facet.KIQs.length,
    facet.VENTUREs.length,
    startDate,
    endDate,
  ]);

  // This will get called once
  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.news
    ) {
      getCompanysList();
      getPublisherList();
      authData?.externalAuthorization?.topics && getTopicList();
      authData?.externalAuthorization?.products && getProductList();
      authData?.externalAuthorization?.kiq && getKiqList();
      authData?.externalAuthorization?.avCompany && getAmgenVentureList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilter = () => {
    setFacet({
      COMPANY: [],
      PRODUCT: [],
      TOPIC: [],
      COREX_TOPIC: [],
      KIQs: [],
      PUBLISHER: [],
      VENTUREs: []
    });
    setPage(1);
    setStartDate(dayjs().subtract(89, 'days'));
    setEndDate(dayjs());
  };

  const formatFilterCount = (filterCount: number) => {
    if(filterCount === 0) return ""
    return `(${filterCount})`
  }

  const { authData } = useContext(AppContext);

  let items = [
    {
      children: <FilterDropdown
        label="PUBLISHER"
        options={availablePublishedList}
        isLoading={isPublishedFilterLoading}
        checkedOptions={facet.PUBLISHER}
        onClickCheckbox={getSelectedValue}
        error={publishedListError}
      />,
      key: '1',
      label: `Publisher ${formatFilterCount(facet['PUBLISHER'].length)}`
    },
    {
      children: <FilterDropdown
        label="COMPANY"
        isLoading={isCompanFilterLoading}
        options={availableCompanyList}
        checkedOptions={facet.COMPANY}
        onClickCheckbox={getSelectedValue}
        error={companyListError}
      />,
      key: '2',
      label: `Company ${formatFilterCount(facet['COMPANY'].length)}`
    }
  ]

    if(authData?.externalAuthorization?.products){
      items.push({
        children: <FilterDropdown
          label="PRODUCT"
          options={productList}
          isLoading={isProductListLoading}
          checkedOptions={facet.PRODUCT}
          onClickCheckbox={getSelectedValue}
          error={productsListError}
        />,
        key: '3',
        label: `Product ${formatFilterCount(facet['PRODUCT'].length)}`
      })
    }
    if(authData?.externalAuthorization?.kiq){
      items.push({
        children: <FilterDropdown
          label="KIQs"
          options={kiqList}
          isLoading={isKiqListLoading}
          checkedOptions={facet.KIQs}
          onClickCheckbox={getSelectedValue}
          error={kiqListError}
        />,
        key: '4',
        label: `KIQs ${formatFilterCount(facet['KIQs'].length)}`
      })
    }
    if(authData?.externalAuthorization?.topics){
      items.push({
        children: <>
          <Input
            className="topic-search"
            placeholder="Search Topics"
            value={searchedValue}
            onChange={(e: any) => fetchTopicList(e)}
            suffixContent={<SearchIcon width={16} height={16} className='topic-search-icon' fill="rgba(0, 0, 0, 0.45)"/>}
          />
          <FilterDropdown
            label="TOPIC"
            options={topicList}
            isLoading={isTopicListLoading}
            checkedOptions={[...facet.TOPIC, ...facet.COREX_TOPIC]}
            onClickCheckbox={getSelectedValue}
            error={topicsListError}
          />
        </>,
        key: '5',
        label: `Topic ${formatFilterCount([...facet.TOPIC, ...facet.COREX_TOPIC].length)}`
      })
    }
    if(authData?.externalAuthorization?.avCompany){
      items.push({
        children: <FilterDropdown
          label="VENTUREs"
          options={avCompanyList}
          isLoading={isAvCompanyListLoading}
          checkedOptions={facet.VENTUREs}
          onClickCheckbox={getSelectedValue}
          error={avCompanyListError}
        />,
        key: '6',
        label: `VENTUREs ${formatFilterCount(facet['VENTUREs'].length)}`
      })
    }

  return authData && authData?.externalAuthorization?.news ? (
    <div className="ext-oncology">
      <Title level={1}>News Article Search</Title>
      <div className="ext-news-container">
        <div className="ext-news-left-panel">
            <CardFilter
              cardHeader="Filter Articles"
              totalFilterCount={totalFilterCount}
            >
              <div className="ext-filter-element-container">
                <DateSelector
                  selectedDate={startDate}
                  label="PUBLISHED BETWEEN"
                  verbageText="Select publish date"
                  minDate={dayjs("2022-01-03")}
                  maxDate={endDate}
                  onChange={(date) =>
                    onDateChange({
                      data: date,
                      fieldName: "fromDate",
                    })
                  }
                />
                <DateSelector
                  selectedDate={endDate}
                  label="AND"
                  minDate={startDate}
                  maxDate={todayDate}
                  onChange={(date) =>
                    onDateChange({ data: date, fieldName: "toDate" })
                  }
                />
                <div style={{minHeight: 24, width: '100%'}}/>
                <Collapse
                  defaultActiveKey={1}
                  items={items}
                />
              </div>
              <div className="ext-reset-filters">
                <Button
                  text="RESET FILTERS"
                  type="link"
                  onClick={() => resetFilter()}
                />
              </div>
            </CardFilter>
        </div>
        {
          <div className="ext-news-right-panel">
            <Card cardHeader="Articles" newsCard={true}>
              <div className="ext-article-container">
                <div className="ext-article-sorting-wrapper">
                  <Paragraph className="ext-article-card-note">
                     {SEARCH_TEXT}
                  </Paragraph>
                  <div className="ext-card-toggle-options">
                    <div className="ext-show-summary-parent">
                      <Switch
                        checked={showArticleSummary}
                        onChange={() => setShowArticleSummary(!showArticleSummary)}
                        inlineText={SWITCH_SUMMARIES_TEXT}
                      />      
                    </div>
                    <Select
                      options={SELECT_OPTIONS}
                      defaultValue="desc"
                      onChange={(val) => setSortOrder(val)}
                    />
                </div>
              </div>
              <div className="ext-article-list-wrapper">
                {isLoading && (
                  <div className="ext-news-loader">
                    <Loader />
                  </div>
                )}
                {articleListError && (
                  <ErrorMessage isIcon={false} message={articleListError} />
                )}
                {!isLoading &&
                  !articleListError &&
                  articleList?.map((article: any, index: number) => {
                    let isEven = index % 2 === 0

                    const tags = getFormatedTagListWithFilter(
                      navigate,
                      location,
                      facet,
                      getSelectedValue,
                      article.company_names,
                      article.product_names,
                      article.topic_names,
                      article.kiq_names,
                      article.av_company_names,
                      article.corex_topic_names
                    );

                    return (
                      <ArticleCard
                        key={index}
                        redirectUrl={article.url}
                        isEven={isEven}
                        title={article.title}
                        articleId={article.article_id}
                        publishDate={article.published}
                        publishSource={article.source_name}
                        tagList={tags}
                        checkboxFlag={false}
                        trashFlag={false}
                        summary={showArticleSummary ? article.article_summary : null}
                        ellipsisRequired={ellipsisRequired}
                      />
                    );
                  })}
              </div>

              <div className="ext-article-pagination">
                <div className="pagination-container">
                    {!isLoading && articleList?.length !== 0 &&
                    <div className="ext-article-pagination-wrapper">
                      <Paragraph>
                        Last update at {paginationDate?.replace("T", "T on")}
                      </Paragraph>
                      <Pagination
                        showSizeChanger={false}
                        showTotal={(total) => `${lowerLimit} - ${currentLimit} of ${newsDataObj.count} items`}
                        current={page}
                        total={newsDataObj.count || 0}
                        pageSize={limit}
                        onChange={(page: number) => handlePages(page)}
                      />
                  </div>
                }
                  </div>
                </div>
              </div>
            </Card>
          </div>
        }
      </div>
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default News;

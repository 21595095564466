import React, { useState, useEffect } from "react";
import './index.scss';
import { Card, BarChart} from '@opsdti-global-component-library/amgen-design-system';
import axios from "axios";
import { getApiUrl } from "../../../../api/api-config";
import { getOktaToken } from "../../../../utils/commonMethods";
import { noDataMessage, apiErrorMessage } from "../../../../assets/errorMessageConstants";
import { Loader } from "@gitlab-rtsensing/component-library";

type ComparativePEValuesProp = {
    popOverContent?: string;
    cardTitle?: string;
};




export const ComparativePEValues: React.FC<ComparativePEValuesProp> = ({ cardTitle, popOverContent}: ComparativePEValuesProp) => {
    type ItemType = {
        pe: number;
        company: string;
    };
 
    const [data, setData] = useState<ItemType[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState("");
    const oktaToken = getOktaToken();

    const getBenchmarkPEValuesData = async () => {
    
        try {
            const res = await axios({
                method: "get",
                url: `${getApiUrl("get-comparative-pe")}`,
                headers: {
                    Authorization: `Bearer ${oktaToken}`,
                },
            });
            if (res?.data?.status === "SUCCESS") {
               setIsError("")
               setData(res.data.data);
            } else {
                setIsError(noDataMessage);
            }
        } catch (error) {
            setIsError(apiErrorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getBenchmarkPEValuesData();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>{isError}</div>;
    }
    const dataKeys = [{barKey: 'npv',barLabel: 'label'}]

    const getBarLabelColor =(benchmark_label: string) => {
        if (benchmark_label == "AMGEN") {
            return ["AMGEN", "#0063C3"];
        } else if (benchmark_label == "S&P 500") {
            return ["S&P 500", "#FDB81E"];
        } else if (benchmark_label == "PEERSET AVERAGE"){
            return ["PEERSET-AVERAGE", "#2FBCB6"];
        } else {
            return [benchmark_label, "#2FBCB6"]
        }
        
    }



    const maxPE = Math.max(...data.map(item => item.pe));

    const yAxisTicks = Math.ceil(maxPE/5) + 1 ;
    const yAxisDomain = [0, Math.ceil(maxPE/5) * 5];


    const dataSet = data.map((item: any, index: number) => {
        const [bar_label, bar_color] = getBarLabelColor(item.entity_name);

        return {
            label: `${item.pe.toLocaleString()}X`,
            npv: item.pe,
            benchmark_label: bar_label,
            color: bar_color
        }
          
    });

    return (
        <div className="ext-intelligence-comparative-pe">
            <div className="ext-intelligence-comparative-pe-container">
                <Card title={{title: cardTitle, popoverIconColor: "secondary", popoverContent: popOverContent}}>                
                    <BarChart  data={dataSet} xKey="benchmark_label" yAxisLabel="PE MULTIPLE" yAxisDomain={yAxisDomain} yAxisTickCount={yAxisTicks}
                     dataKeys={dataKeys} yAxisTickFormatter={(value) => `${value.toLocaleString()}X`} showMultiLineXAxisTick/>
                </Card>
            </div>
        </div>
    );
};



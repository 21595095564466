import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.scss";
import {
  CompaniesTableData,
  CompaniesColumn,
} from "../../api/get-companies-dt";
import ErrorMessage from "../../components/error-message";
import { Loader } from "@gitlab-rtsensing/component-library";
import RedDownwardIcon from "../../assets/icons/red-downward-icon";
import GreenUpwardIcon from "../../assets/icons/green-upward-icon";
import SortDownIcon from "../../assets/icons/sort-down-icon";
import SortUpIcon from "../../assets/icons/sort-up-icon";
import "../../assets/css/bootstrap.scss";
import { BASE_ROUTE } from "../../components/../utils/routing";
import CustomRange from "../../components/custom-range";

type CompanyListProp = {
  tableData: CompaniesTableData[];
  columns: CompaniesColumn[];
  isFirstLoading: boolean;
};
const CompanyList: React.FC<CompanyListProp> = (props: CompanyListProp) => {
  const { tableData, columns, isFirstLoading } = props;
  const navigate = useNavigate();
  const currentRoute = useLocation();
  const [priceHistoryViews, setPriceHistoryViews] = useState([
    { name: "1Y", value: true },
    { name: "3Y", value: false },
    { name: "5Y", value: false },
  ]);
  const handlePriceView = (selectedView: any) => {
    const updatedPriceHistoryViews = priceHistoryViews.map((view) => {
      if (view.name === selectedView.name) {
        view.value = true;
      } else {
        view.value = false;
      }
      return view;
    });
    setPriceHistoryViews([...updatedPriceHistoryViews]);
  };
  const priceHistory = (value: any, year: string) => {
    return (
      <div className="ext-d-flex">
        <span className={value < 0 ? "ext-red-color" : "ext-green-color"}>
          <span className="ops-pb-1 ops-me-2">
            {value < 0 ? (
              <RedDownwardIcon classname="ext-vertical-align" />
            ) : (
              <GreenUpwardIcon classname="ext-vertical-align" />
            )}
          </span>
          {value > 0 && "+"}
          {Number.parseFloat(value).toFixed(1)}%
        </span>
        {year === "1Y" ? (
          <span className="ops-px-2"> in last year</span>
        ) : year === "3Y" ? (
          <span className="ops-px-2"> in last 3 years</span>
        ) : (
          year === "5Y" && <span className="ops-px-2"> in last 5 years</span>
        )}
      </div>
    );
  };
  return (
    <div>
      <div className="ext-companies-list table-responsive">
        <table className="table">
          <thead>
            <tr className="table-header">
              {columns.map((column, index) => {
                return (
                  <th scope="ext-row" key={column.key}>
                    <div
                      className="ext-d-flex ext-cursor-pointer ops-pe-4 ops-ps-2"
                      onClick={() => {
                        column.sort.onSort(index, tableData);
                      }}
                    >
                      <div className="ops-pb-2">{column.title}</div>
                      {column.title === "Price History" && (
                        <div className="navbar-section ops-ms-2">
                          <ul className="nav ext-border-bottom ext-section-name ext-display-item-inline ext-cursor-pointer">
                            {priceHistoryViews.map((view) => {
                              return (
                                <li
                                  key={view.name}
                                  className={
                                    view.value
                                      ? "nav-item active ops-px-2 ops-pb-1"
                                      : "nav-item inactive ops-px-2 fw-light text-muted ops-pb-1"
                                  }
                                  onClick={() => handlePriceView(view)}
                                >
                                  {view.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      {column.isSorting && (
                        <div className="ext-ms-auto">
                          <span className="ext-col">
                            <span className="ext-row ops-mb-1">
                              <SortUpIcon
                                fill={
                                  column.sort.isSorted &&
                                  column.sort.direction === "asc"
                                    ? "#0063c3"
                                    : column.sort.active
                                    ? "#bcbcbc"
                                    : "#2F2F2F"
                                }
                              />
                            </span>
                            <span className="ext-row">
                              <SortDownIcon
                                fill={
                                  column.sort.isSorted &&
                                  column.sort.direction === "desc"
                                    ? "#0063c3"
                                    : column.sort.active
                                    ? "#bcbcbc"
                                    : "#2F2F2F"
                                }
                              />
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {isFirstLoading && (
              <tr>
                <td colSpan={columns.length} className="ext-companies-loader">
                  <Loader />
                </td>
              </tr>
            )}
            {!isFirstLoading &&
              tableData.length === 0 && (
                <tr>
                  <td>
                    <ErrorMessage
                      message="Companies not found"
                      isIcon={false}
                    />
                  </td>
                </tr>
              )}
            {!isFirstLoading &&
              tableData.length !== 0 &&
              tableData.map((item: any) => (
                <tr key={item.company_ticker} className="column-height">
                  <td className="ext-align-middle ext-company-details">
                    <div
                      className="ext-company-name ext-d-flex ext-cursor-pointer ops-ps-2 w-75"
                      onClick={() => {
                        navigate(`${BASE_ROUTE}/companies/${item.company_ticker}`, {
                          state: {
                            companyId: item?.company_ticker,
                            callingPath: currentRoute,
                          },
                        });
                      }}
                    >
                      <img
                        src={item.company_logo_url}
                        alt={item.company_name}
                        className="ext-company-logo"
                      />
                      <span className="text-decoration-underline ext-company-fonts ops-ms-2">
                        {item.company_name}
                      </span>
                    </div>
                  </td>
                  <td className="ext-align-middle ext-company-fonts">
                    <div className="ops-ps-2">
                      ${Number.parseFloat(item.company_price).toFixed(1)}
                    </div>
                  </td>
                  <td className="ext-align-middle ext-company-fonts">
                    <div className="ops-ps-2">
                      {item.company_today > 0 && "+"}
                      {Number.parseFloat(item.company_today).toFixed(1)}%
                    </div>
                  </td>
                  <td className="ext-align-middle ext-price-history ext-company-fonts">
                    <div className="ops-ps-2">
                      {priceHistoryViews[0].value
                        ? priceHistory(
                            item.company_price_history.value_1Y,
                            "1Y"
                          )
                        : priceHistoryViews[1].value
                        ? priceHistory(
                            item.company_price_history.value_3Y,
                            "3Y"
                          )
                        : priceHistoryViews[2].value &&
                          priceHistory(
                            item.company_price_history.value_5Y,
                            "5Y"
                          )}
                    </div>
                  </td>
                  <td className="ext-align-middle ext-week-range">
                    <div className="ops-ps-2">
                     <CustomRange minValue={item.company_yearly_range.lower_value} maxValue={item.company_yearly_range.upper_value} value={item.company_yearly_range.current_value} />
                      <div className="ext-d-flex ext-justify-content-between">
                        <span className="ext-text-start ops-mt-2">
                          $
                          {Number.parseFloat(
                            item.company_yearly_range.lower_value
                          ).toFixed(1)}
                        </span>
                        <span className="ext-text-end ops-mt-2 ops-mx-1">
                          $
                          {Number.parseFloat(
                            item.company_yearly_range.upper_value
                          ).toFixed(1)}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="ext-align-middle ext-company-fonts">
                    <div className="ops-ps-2">
                      {Number.parseFloat(item.company_pe_ratio).toFixed(1)}x
                    </div>
                  </td>
                  <td className="ext-align-middle ext-company-fonts">
                    <div className="ops-ps-2">
                      {Number.parseFloat(item.company_mkt_cap).toFixed(1)}B
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CompanyList;

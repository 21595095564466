import React, { useState } from "react";
import "./index.scss";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BarChart,
  Bar,
  LabelList,
  YAxis,
  ResponsiveContainer,
  XAxis,
  Cell,
  CartesianGrid,
} from "recharts";
import { BASE_ROUTE } from "../../../utils/routing";
type Props = {
  data?: any;
  top: number;
  chartType: string;
  right: number;
  left: number;
  bottom: number;
  duration: string;
};

const BarGraph: React.FC<Props> = (props: Props) => {
  const { data, top, right, left, bottom, chartType, duration } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const convertUpperCase = (data: string) => {
    return data.replace(/(^\w{1})|(\s+\w{1})/g, (data) => data.toUpperCase());
  };
  const [focusBar, setFocusBar] = useState(null);
  let respData: any = [];
  let zeroValues: any = [];
  data.map((ele: any) =>
    respData.push({
      name:
        ele &&
        ele.name &&
        convertUpperCase(ele.name.replace("-", " ").toUpperCase()),
      art_count: ele.art_count,
      ticker:
        chartType === "company"
          ? ele.ticker.toUpperCase()
          : ele.name,
      company_id: ele.company_id,
    })
  );

  data.map(
    (ele: any) =>
      ele.art_count !== 0 && zeroValues.push({ count: ele.art_count })
  );

  const graphHeight = respData.length === 1 ? 60 : respData.length * 50;

  const handleBarClick = (e: any) => {
    if (chartType === "company") {
      navigate(`${BASE_ROUTE}/companies/${e.ticker}`, {
        state: { companyId: e.ticker, callingPath: location },
      });
    }
    if (chartType === "product") {
      const productName = e.ticker;
      navigate(`${BASE_ROUTE}/products/${productName}`, {
        state: { productName: productName, callingPath: location },
      });
    }
  };

  const handleGraphLabelClick = (e: any) => {
    const labelTicker = respData.filter((data: any) => {
      if (data.name === e.value) {
        return true;
      }
    });
    if (chartType === "company") {
      navigate(`${BASE_ROUTE}/companies/${labelTicker[0].ticker}`, {
        state: { companyId: labelTicker[0].ticker, callingPath: location },
      });
    }
    if (chartType === "product") {
      const productName = e.value;
      navigate(`${BASE_ROUTE}/products/${productName}`, {
        state: { productName: productName, callingPath: location },
      });
    }
  };

  return (
    <div className="ext-bar-graph-wrapper">
      <div
        className="ops-mt-2  ext-barchart-container"
        style={{ height: `${graphHeight}px` }}
      >
        {zeroValues.length !== 0 ? (
          <ResponsiveContainer width="100%">
            <BarChart
              barCategoryGap={40}
              barSize={18}
              data={respData}
              layout="vertical"
              margin={{ top: top, right: right, left: left, bottom: bottom }}
            >
              <CartesianGrid horizontal={false} stroke="#E9E9E9" />
              <XAxis
                type="number"
                axisLine={false}
                tickLine={false}
                style={{
                  fontSize: "10px",
                  fontFamily: "Inter",
                  fontWeight: 700,
                  lineHeight: "14px",
                  color: "#9C9C9C",
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                type="category"
                width={90}
                dx={-5}
                padding={{}}
                dataKey="name"
                style={{
                  fontSize: "10px",
                  fontFamily: "Inter",
                  fontWeight: 700,
                  lineHeight: "14px",
                  color: "#9C9C9C",
                  cursor: "pointer",
                }}
                onClick={handleGraphLabelClick}
              />
              <Bar
                dataKey="art_count"
                onClick={handleBarClick}
                isAnimationActive={false}
                style={{ cursor: " pointer" }}
                onMouseEnter={(state) => {
                  if (state.name) {
                    setFocusBar(state.name.toLowerCase());
                  } else {
                    setFocusBar(null);
                  }
                }}
                onMouseLeave={() => setFocusBar(null)}
              >
                {data.map((entry: any, index: any) => (
                  <Cell
                    fill={
                      focusBar === entry.name ||
                      focusBar === entry.name.toLowerCase().replaceAll("-", " ")
                        ? "#3399ff"
                        : "#0063C3"
                    }
                    key={index}
                  />
                ))}
                <LabelList
                  dataKey="art_count"
                  position="right"
                  style={{
                    fontSize: "10px",
                    fill: "#585858",
                    fontFamily: "Inter",
                    fontWeight: "700",
                  }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className="ext-empty-chart-msg">
            No articles in the news for{" "}
            {duration.toLowerCase().replace("_", " ")}
          </div>
        )}
      </div>
    </div>
  );
};

export default BarGraph;
